import React, { FC } from 'react';

import { PostListItem } from 'src/interfaces/post';
import PageHeader from 'src/components/organisms/common/PageHeader';
import PostList from 'src/components/organisms/post/PostList';

interface PropsType {
  title: string;
  posts: PostListItem[];
}

const Series: FC<PropsType> = ({ title, posts }) => {
  return (
    <>
      <PageHeader title={title} />
      <PostList posts={posts} />
    </>
  );
};

export default Series;
