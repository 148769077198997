import React, { FC, memo } from 'react';
import { graphql } from 'gatsby';

import { ISeries, ITemplateProps } from 'src/interfaces/template';
import SeriesTemplate from 'src/components/templates/series/SeriesTemplate';

interface PropsType
  extends ITemplateProps<{
    series: ISeries;
  }> {}

const SeriesPageTemplate: FC<PropsType> = memo(({ pageContext, data }) => {
  return <SeriesTemplate data={data as GatsbyTypes.Query} series={pageContext.series} />;
});

export default SeriesPageTemplate;

export const pageQuery = graphql`
  query SeriesPage($name: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { published: { eq: true }, series: { eq: $name } } }
    ) {
      edges {
        node {
          excerpt(truncate: true, pruneLength: 300)
          frontmatter {
            title
            date
            slug
            tags
            series
            comments
            published
            cover {
              id
              absolutePath
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          id
        }
      }
    }
  }
`;
