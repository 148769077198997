import React, { FC } from 'react';

import SEO from 'src/components/organisms/common/SEO';
import Series from 'src/components/organisms/series/Series';
import { ISeries } from 'src/interfaces/template';
import usePosts from 'src/hooks/usePosts';
import Layout from 'src/components/templates/layout/Layout';

interface PropsType {
  data: GatsbyTypes.Query;
  series: ISeries;
}

const SeriesTemplate: FC<PropsType> = ({ data, series }) => {
  const title = `Series: ${series.name}`;
  const posts = usePosts(data.allMarkdownRemark);

  return (
    <Layout>
      <SEO title={title} />
      <Series title={title} posts={posts} />
    </Layout>
  );
};

export default SeriesTemplate;
